@import 'styles/base/mixins.scss';

.buttons-panel{
 
  .button {
    font-size: 14px;
    &--half {
      max-width: 48%;
    }
  }
  .form-content{
    padding-top: 0;
  }
  &.flex {
    @include flex(row, center, space-between!important);
  }
}
