@import 'src/styles/base/variables.scss';

.email-modal{
    input{
        width: 100%;
        margin-bottom: 10px;
    }
 
}

.address-header{
    font-weight: 500;
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 10px;
}

.report-email{
    :global(.caption){
        width: 75px !important;
    }
}

.report-container{
    :global(.ant-table){
        border :  1px solid $light-grey2;
    }
    :global(.row-summary){
        .text-right{
            text-align: right;
        }
    }
}