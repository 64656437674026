@import "styles/base/variables.scss";
@import "styles/base/mixins.scss";

.login-page {
  // font-size: 12px;
  .bottom-links {
    text-align: center;
    padding-top: 40px;
    
    width: 100%;
    // font-size: 12px;
    font-size : 14px;
    // padding-bottom: 60px;
    a{
      padding: 5px;
      
    }
  }
  .close-link{

    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 999;
    z-index: 99999;
  
    
  }
  .close {
  
    z-index: 999;
    color: $grey;
    cursor: pointer;
  }
}

.back {
  left: 30px;
  top: 80px;
}

.check-circle {
  font-size: 60px !important;
}


.login {
  h1 {
    // @include font(26px, 600, 40px, 0);
    font-family: "sofia-pro";
    color: $heading;
  }

  padding: 0 100px;

  &.ant-row-flex {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  section {
    @include position(absolute, 50%, 0, "", 0);
    transform: translateY(-50%);
    margin: 0 auto;
    padding: 0 80px;

    .login-form {
      max-width: 380px;
      position: relative;
      margin: auto;

      p {
        // font-size: 12px;
        // line-height: 1.4;
      }
    }
  }

  h1,
  .store-btns {
    a {
      float: left;

      &:first-child {
        margin-right: 15px;
      }
    }

    img {
      width: 142px;
    }
  }
  @media (max-width: $md-size) {
    section {
      padding: 0 20px;
    }

    .store-info {
      display: none;
    }

    .login {
      .login-form {
        max-width: none;
      }
    }
  }
}
// mobile info overlay screen
.mobile-info {
  h1,
  h2 {
    text-align: center;
  }
}
// context-specified prefix phone droprown
.ant-select,
.ant-select-dropdown {
  .ant-select-dropdown-menu-item {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .prefix-option {
    width: 300px;
    line-height: 32px;
    vertical-align: middle;

    .tel-prefix {
      float: right;
    }

    > span {
      line-height: 32px;
    }

    img {
      margin-right: 10px;
      line-height: 32px;
      vertical-align: middle;
      height: 16px;
      width: 22px;
    }
  }

  .ant-select-selection-selected-value {
    .prefix-option {
      width: auto;
    }

    .country {
      display: none;
    }
  }
}

// .ant-radio-group{
//   width: 350px;

// }

.ant-radio-wrapper {
  &.large {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    white-space: normal;
    span.ant-radio + * {
      padding-left: 20px;
      display: inline-block;
    }
    .ant-radio-inner {
      width: 18px;
      height: 18px;
      &:after {
        width: 10px;
        height: 10px;
      }
    }
  }
}
