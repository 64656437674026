@import 'styles/base/mixins.scss';
.nav-logo{
      display: inline-block;
    
  img{
    width: 40px;
    margin-right: 6px;
    // position: relative;
    // top: 10px;
  }
  h1 {  
    display: flex;
          @include font(27px, 800, "", -1.28px);
          color: #fff;
          margin: 0;
          font-family: "sofia-pro";
    }
}
