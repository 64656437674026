@import 'src/styles/base/variables.scss';
@import 'src/styles/base/mixins.scss';

.top-message{
    background-color: $skin-color;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;

    &--success{
        background-color: transparent;
        border: 2px solid $green;
        border-radius: 0;
        box-shadow: none;

        .status-icon{
            font-size: 38px;
            margin-right: 15px;
        }
    }

    &--error{
        background-color: transparent;
        border: 2px solid red;
        border-radius: 0;
        box-shadow: none;

        .status-icon{
            font-size: 38px;
            margin-right: 15px;
        }
    }
}

.error-message{
    border: 2px solid red;
    padding: 15px;
    margin-bottom: 20px;

    .icon{
        padding-right: 15px;
    }
}