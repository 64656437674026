.product-charges{
    .table-list{
        .agreement-status{
            padding-right: 20px;
        }
        .date{
            padding-right: 20px;
        }
        .help{
            padding-left: 20px;
        }
    }

    .product-item{
        align-items: center !important;
        display: flex;
        margin-bottom: 20px;
        max-width: 700px;
     
    }

    p{
        margin-bottom: 0;
    }

    .agreement-status{
        padding-right: 20px;
        svg{
            font-size: 35px;
        }
        
    }
}
