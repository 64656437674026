@import "styles/base/variables.scss";

p {
  line-height: 25px;
}
.mass-table {
  border-collapse: separate;
  border-spacing: 10px;

  .is-border {
    border-radius: 5px;
    border: 1px solid #dbdbdb;
  }

  th,
  td {
    text-align: center;
    vertical-align: middle;
    padding: 5px;
    height: 64px;
  }

  .title {
    font-size: 14px;
    font-weight: 700;
    line-height: 25px; /* 178.571% */
  }

  .so-header {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
  }

  .so-slider-header {
    text-align: center;

    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 0;
  }
  .so-slider-subheader {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }
}

.summary-table {
  border-collapse: separate;
  border-spacing: 2px;

  .is-border {
    border-radius: 5px;
    border: 1px solid #dbdbdb;
  }

  th,
  td {
    text-align: center;
    vertical-align: middle;
    padding: 5px;
    min-width: 100px;
  }
}
