@import 'src/styles/base/variables.scss';
@import 'src/styles/base/mixins.scss';

.page-header{
      margin-bottom: 25px;

      .back{
        position: static;
        display: inline-block;
        margin-right: 10px;
      }
}

.simple-header{
  background-color : transparent;
  display: flex;
align-items: center;
height: 64px;
padding: 30px 165px 0 165px;
line-height: 64px;
  .nav-logo h1{
    color : $black;
  }
  position:fixed;
  z-index: 999;
  width:100%;

  span{
    font-size: 12px;
    font-family: 'sofia-pro';
    color : $white;
    max-width: 180px;
        line-height: 1.2;
        display:inline-block;
        margin-left:12px;

  }
}