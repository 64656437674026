@import 'src/styles/base/variables.scss';
@import 'src/styles/base/mixins.scss';

#notifications {
  padding: 10px 7px;
  h2 {
    @include flex(row, center, flex-start);
    // font-family: sofia-pro;
    // color: $heading;
    .ant-badge {
      margin-left: 10px;
      color: white;
      /deep/ {
        .ant-badge-count {
          box-shadow: none;
          border: 0;
        }
      }
    }
  }
  ul {
    margin-top: 12px;
  }
}
