@import 'styles/base/variables.scss';
.toast-content{
  z-index: 999999;
  display: flex;
  align-items: center;
  .MuiSvgIcon-root{
    line-height: 30px;
margin-right: 10px;
/* vertical-align: sub; */
height: 30px;
vertical-align: middle;
position: relative;
bottom:2px;
  }
}

.custom-toaster{
  
  
  .Toastify__progress-bar{
    background-color:darken($grey3, 7%);
  }
  background-color:white;
  padding: 15px 15px;
  border-radius: 0;
  .toast-title{
    color : $heading;
    font-family: "sofia-pro";
    // margin-bottom: 7px;
  }
  .toast-close{
    color: grey;
    position: absolute;
    right: 7px;
    top: 7px;
  }
}