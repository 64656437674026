@import "styles/base/variables.scss";
@import "styles/base/mixins.scss";

$panel-gray: #e6e8ea60;
$padding: 25px;

.company-circle {
  background-color: $primary;
  font-family: Roboto;
  font-weight: bold;
  font-size: 18px;
  line-height: 40px;
  border-radius: 60px;
  width: 40px;
  min-width: 40px;
  text-align: center;
  color: white;
}

#layout {
  min-height: 100vh;

  .trigger {
    font-size: 18px;
    margin-left: -10px;
    margin-right: 20px;
    cursor: pointer;
    transition: opacity 0.3s;
    color: #fff;
    padding-top: 4px;
    @media only screen and (max-width: 992px) {
      margin: 0 20px 0 20px;
    }
    &:hover {
      opacity: 0.6;
    }
  }
  .flex {
    @include flex(row, center, left);
  }
  .search {
    max-width: 280px;
    background-color: #0000001f;
    height: 100%;
    padding-left: 20px;
    input {
      z-index: 9;
      font-size: 16px;
    }
    input:focus {
      border-color: none;
      outline: none !important;

      box-shadow: none;
    }
    .ant-input-suffix {
      left: 15px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    @media only screen and (max-width: 992px) {
      display: none;
    }
    input {
      background-color: transparent;
      border: 0;
      color: #fff;
      padding: 0 25px 0;
      &::placeholder {
        color: rgba(#fff, 0.6);
      }
    }
    i {
      color: #fff;
    }
  }
  .ant-statistic {
    color: #fff;
    font-size: 14px;
  }
  .ant-statistic-title {
    margin: 0;
    color: #ffffffb3 !important;
    font-size: 12px;
    font-family: "Roboto";

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 170px;
  }
  .header {
    &--fixed{
      position: fixed;
      top: 0;
      left:0;
      right:0; 
      z-index: 9999;
    }
    @include flex(row, center, space-between);
    font-family: "Poppins";
    height: 56px;
    padding: 0 24px 0 24px;
    // background-color: $primary;
    background: $primary-blue;
    @media only screen and (max-width: 992px) {
      padding: 0;
    }
    .domain-icon {
      margin-right: 10px;
    }
    &__logo {
      // @include size(200px, 100%);
      margin-right: 30px;
      @include flex(row, center, center);
      @media only screen and (max-width: 992px) {
        width: auto;
      }
      .button {
        margin-left: 20px;
        margin-right: 10px;
      }
    }
    &__pane {
      @include flex(row, center, space-between);
      flex-direction: row;
      align-items: stretch;
      margin-left: auto;
      height: 100%;
      color: #fff;
      .flex {
        margin: 0 10px;
        // color: $dark-blue;
        color: white;
      }
      .ant-badge {
        margin-right: 20px;
        color: white;
        top: 10px;
        img {
          margin: 0;
        }
        .current {
          color: white;
        }
      }
      .ant-badge-count {
        box-shadow: none;
        font-size: 10px;
      }
      img {
        margin: 0 20px;
        &.help {
          margin-right: -10px;
        }
      }
    }
  }
  .content {
    max-height: calc(100vh - 54px);
    overflow-y: auto;
    // min-height: calc(100vh - 54px);
    // display: inline-block;

    // padding: 20px;
    @media only screen and (max-width: 992px) {
      // padding-left: 80px;
    }
    .page-content {
      padding: 20px;

      &.flex{
        display: flex;
        flex-direction: column;
      }
    }
  }
  .notifications {
    background-color: white;
    
    margin-left: 6px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 54px);
    padding-bottom: 48px;
    @media only screen and (max-width: 992px) {
      position: absolute;
      right: 0;
      background: rgb(240, 242, 245);
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
      z-index: 999;
    }
  }
  .sidebar {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 54px);
    // min-height: calc(100vh - 54px) ;
    // height: calc(100vh - 54px) ;
    padding-bottom: 48px;
    background-color: #fff;

    .manage-menu {
      @include fontSimple("Roboto", 14px, 16px);
      cursor: pointer;
      // font: Regular 14px/16px Roboto;
      letter-spacing: 0;
      color: $grey;
      padding: 0 22px;
      opacity: 1;
    }

    .menu-settings {
      width: 300px;
      h3 {
        margin-bottom: 10px;
      }
      .menu-section {
        padding: 15px 28px;
        border-bottom: 1px solid $light-grey;
      }
    }
    .ant-checkbox + span {
      color: $black;
    }

    @media only screen and (max-width: 992px) {
      position: absolute;
      left: 0;
      height: 100%;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
      z-index: 999;
    }
    .buttons-panel {
      padding: 15px 28px;
    }
  }
  .flex.manage {
    color: white;
    padding-left: 10px;
    padding-right: 5px;
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    cursor: pointer;
  }
  .flex.partner {
    img {
      width: 15px;
      margin-right: 5px;
    }
    color: white;
    padding-left: 30px;
    padding-right: 5px;
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    height: 70%;
    border-right: none;
    text-transform: uppercase;
    span {
      color: $white !important;
      font-size: 12px;
      font-family: "Roboto";
      text-transform: none;
    }
  }
}
.flex.profile-details {
  padding-right: 0;
  margin-right: -10px;
  cursor: pointer;
}

.sider_container {
  position: relative;
  overflow-y: auto;
}

.main_container {
  overflow-y: auto;
  position: relative;
  section {
    margin-bottom: $padding;
  }
}

.top-info {
  background-color: white;
  padding: 25px;
  padding-left: 40px;
}

.user-icon {
  cursor: pointer;
  font-size: 40px;
}

.user-menu {
  width: 600px;
}
.user-dropdown {
  width: 320px;
  max-height: 80vh;
  overflow-y: auto;
  color: $black;

  .company-circle {
    margin-right: 10px;
  }
}
.menu_dropdown {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px 0px;
  background-color: white;

  &--actions{
    section{
      cursor: pointer;
    }
  }

  section {
    border-bottom: 1px solid $light-grey;
    padding: 16px 20px;
    &.no-border {
      border-bottom: 0 !important;
    }
  }
}

.company-profile-dropdown {
  a,
  a:hover {
    color: $black;
  }
  img {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
  a.flex {
    height: 100%;
  }
}

.ant-menu.menu_dropdown {
  //
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px 0px !important;
}

.shortcuts {
  width: 160px;
  height: 100%;
  margin-left: 40px;
  padding-right: 20px !important;
  svg {
    margin-right: 10px;
    font-size: 26px;
  }
}

// * new-integrator * //

.user-dropdown {
  li {
    margin-bottom: 15px;
  }
  .links {
    font-size: 18px;
    font-weight: bold;
  }
  a {
    color: $blue;
  }
}



.sider-page {
  display: flex;
  > main {
    flex-grow: 1;
  }
  height: 100%;
  .sider-header {
    background-color: white;
    padding: 28px 24px;
    padding-bottom: 15px;
  }
  .sider-body {
    background-color: $background2;
    padding: 28px 24px;
    flex-grow: 1;
    overflow-y: scroll;
  }
  .sider-footer{
    background-color: white;
    padding: 10px 24px;
  }
  .sider {
    position: relative;
    width: 717px;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - #{$navbar-height});
    right: 0;
    overflow-x: auto;
    z-index: 999;
    min-width: 400px;
    background-color: white;

    .sider-close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      color: $grey;
    }

    &.fixed {
      position: fixed;
      overflow-x: auto;
      height: calc(100vh - #{$navbar-height});
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
      top: 56px;
    }
  }

  &.form-content{
    .sider {
      max-height: calc(100vh - #{$navbar-height} - #{$footer-height});
    }
  }
}

.list-filters {
  margin-bottom: 20px;
  padding: 15px;
  padding-bottom: 0;
  background-color: $panel-gray;
}

@media screen and (max-width: 1350px) {
  .sider-page {
    .sider:not(.fixed) {
      width: 300px !important;
      max-width: 300px !important;
      min-width: 300px !important;
    }
  }
}

.bor-dash-left{
  border-left : 1px dashed $light-grey ;
}



.close-page{
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 999;
  
}

@media screen and (max-width: 1600px){
  div[class*="ant-col-xxl"] {
    flex : 100%;
  }
}