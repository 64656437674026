

.homepage {
  // margin: 24px 4px 24px 24px;
  @media only screen and (max-width: 992px) {
    margin: 8px;
  }
  .block {
    background-color: #fff;
    box-shadow: 0px 1px 3px #00000014;
    padding: 24px;
    border-radius: 5px;
    margin-bottom: 20px;
    
    h2 {
      margin-bottom: 16px;
    }
    h3 {
      margin-bottom: 0;
    }
    p {
      margin: 0;

    
    }
  }
  .ant-row {
    margin-bottom: 16px;
    @media only screen and (max-width: 992px) {
      margin-bottom: 8px;
    }
    &:last-of-type {
      margin-bottom: 4px;
    }
  }

}

.check-list{
  p{
    margin-bottom: 0;
  }
  svg{
    margin-right: 10px;
  }
}
