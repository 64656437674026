.os-op1{
.form{
    section{
        max-width: 800px;
         
    }
}

}

.report-actions{
    text-align:right;
    a{
        margin-right: 10px;
        
    }
}