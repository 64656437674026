.cost_summary{
  // max-width: 400px;
  display: inline-block;
  width:100%;
  button {
    min-width: 250px;
  }
  flex{
    align-items: baseline;
  }
  .title{
    margin-right: 5px;
    
    font-size : 14px;
    font-weight: 500;
    font-family: 'Roboto';
  }
  .green{
    display: inline-block;
    float: right;
    font-size: 24px ;
    line-height: normal !important;
    font-family: 'Roboto';
    font-weight: 500;
  }
  .primary-h{
    
    text-align: right;
  }
    .sm-l-t{
      float:right;
      clear: right;
    }
}
