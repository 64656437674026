@import "styles/base/variables.scss";
@import "styles/base/mixins.scss";

.network-provision{
  
 
    // .request-order {
    //     li{
            
    //         position: relative;
    // counter-increment: steps;
    // padding-bottom: 20px;
    // padding-top: 5px;
    // padding-bottom: 20px;
    //         div{
    //             padding-left: 35px;
    //         }
    //         padding-bottom: 20px;

    //         &:before{
    //             position: absolute;
    //             left: 13px;
    //             top: 0;
    //             content: "";
    //             height: 100%;
    //             width: 0;
    //             border-left: 1px solid #dfdfdf;
    //         }
    //         &:last-child:before{
    //             display: none;
    //         }
    //     }
    //     li:after{
    //         position: absolute;
    //         top: 0;
    //         left: 0;
    //         content: counter(steps);
    //         border: 1px solid #dfdfdf;
    //         border-radius: 50%;
    //         color: #4A90E2;
    //         font-weight: 500;
    //         display: inline-block;
    //         height: 28px;
    //         width: 28px;
    //         line-height: 27px;
    //         text-align: center;
    //         background: #fff;
    //         font-size: 14px;
    //     }

    //     p {
    //         // color : $primary;
    //         line-height: 20px;
    //       }
    
    //     label{
    //       // color: $heading;
    //       color : $black;
    //       @include font(14px, 600, 18px, 0);
    //       // margin-bottom: 5px;
    //       display: inline-block;
    //     }
    //   }
    .block{
        h1{
            svg{
                font-size: 46px;
                margin-right: 10px;
            }
        }
       
    
}
.sider{
    flex: 0 0 500px;
    max-width: 500px;
    min-width: 500px;
    width: 500px;
    overflow-x: hidden;
    right: 0;
    overflow-x: auto;
    z-index: 999;
    min-width: 400px;
    background-color: white;
    padding: 28px 24px;
    padding-bottom: 50px;
    height: 100%;
}

// .price{
//     @include font(28px, 500, 20px, 0);
// }
}


