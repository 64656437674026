.green {
  color: $green !important;
}

.red {
  color: $error !important;
  svg{
    font-size : 1.5em;
  }
}

.black {
  color: $black !important;
}

.black-2 {
  color: $black-2 !important;
}

.orange {
  color: $orange !important;
}

.grey {
  color: $grey !important;
}

.primary{
  color : $primary;
}
.heading{
  color : $heading !important;
}

.primary-blue{
  color : $primary-blue !important;
}


.grey-2 {
  color: $dark-blue;
}
.text-42 {
  font-size: 30px !important;
  line-height: 1.4;
}

.text-60 {
  font-size: 60px !important;
}

.text-32 {
  font-size: 32px !important;
  line-height: 1.2 !important;
}

.text-12 {
  font-size: 12px !important;
}

.light {
  font-weight: 300 !important;
}

a {
  text-decoration: none;
}

a.default-link {
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
}

.link{
  color : $primary;
  cursor: pointer;
}

.no-wrap {
  white-space: nowrap;
}

.center {
  text-align: center;
}

.line-through{
  text-decoration: line-through;
}

p {
  text-align: left;
  // font-size: 14px;
  font-family: "Roboto";
  // line-height: 20px;
  @include md-t;
  // color: $grey;
  // color: $default-text;
  color : $black;
  opacity: 1;
  margin-bottom: 15px;

  &.small {
    font-size: 12px;
    line-height: 1.4;
    font-weight: 400;
  }

  &.medium {
    font-weight: 500;
    color: $black;
    line-height: 17px;
  }

  &.large{
    font-size: 16px;
  }

  &.primary {
    font-size: 20px;
    font-family: "sofia-pro";
    color: $black;
    line-height: 28px;
    margin-bottom: 30px;
  }
}

.t-large{
  font-size: 16px;
}

.sofia-pro {
  font-family: "sofia-pro";
}

.sm-l-t {
  @include sm-l-t;
  font-family: "Roboto";
}

.sm-t {
  @include sm-t;
}
.md-t {
  @include md-t;
}
.md-b-t {
  @include md-b-t;
  color: $black;
}

.justify{
  text-align: justify !important;
}

.t-400 {
  font-weight: 400 !important;
}

.t-500 {
  font-weight: 500 !important;
}

.lg-t {
  @include lg-t;
  //  color: $black;
}

.bold {
  // font-weight: 500;
  font-weight: 600;
}

.primary-text {
  color: $primary !important;
  // font-weight: 500;
}



.input-text {
  font-family: "Roboto";
  font-size: 16px;
  color: $black;
  font-weight: 400;
}

.welcome-h {
  font-weight: SemiBold;
  font-size: 43px;
  font-family: "Poppins";
  line-height: 44px;
  color: $heading;
  font-weight: 600;
}

.primary-h {
  color: $black !important;
  font-family: "sofia-pro";
}

.green-h {
  font-family: "Roboto";
  font-weight: 400;
  color: $green;
  font-size: 32px;
  margin-bottom: 15px;
}

h1,
h2,
h3, h4 {
  text-align: left;
  font-family: "Roboto";

  &.primary{
    color: $heading;
  }


}

.roboto{
  font-family: "Roboto";
}

h1 {
  @include font(32px, 600, 32px, 0);
  margin-bottom: 20px;
  font-family : 'Poppins', "Arial";
}
h2 {
  @include font(18px, 600, 26px, 0);
  margin-bottom: 20px;
  font-family: 'Poppins', "Arial";
  // color : $heading;
  color: $black;
}

h3 {
  @include font(20px, 600, 24px, 0);
}

h4 {
  @include font(16px, 600, 16px, 0);
}

h1,h2,h3,h4{
  &.primary {
    font-weight: 600;
    font-family: 'Poppins', "Arial";
    
  }
}

h2.h-lg{
  font-size: 26px;
  margin-bottom: 20px;
  color : $heading;
  line-height: 1.3;
}

.block {
  // h2 {
  //   // same as h3.primary-h
  //   color: $heading;
  //   font-size: 16px;
  //   font-family: "sofia-pro";
  //   line-height: 24px;
  //   margin-bottom: 17px;
  // }
  h3 {
    //smallest header
    color: $black;
    font-weight: 500;
    font-size: 14px;
  }
}

header {
  position: relative;
  &.page-header {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    .back {
      position: static;
      display: inline-block;
      margin-right: 10px;
    }
    // .button {
    //   position: absolute;
    //   right: 0;
    //   top: 10px;
    // }
  }

  h1 {
    display: flex;
    align-items: center;
    @include font(
      34px,
      600,
      40px,
      0
    ); //same as regular h1, but without color & margin
    font-family: "poppins";
    color: $heading;
    margin-bottom: 0;
  }
  p {
    color: $black;
  }
}

.ant-badge {
  p {
    color: white;
  }
}

// header {
//   h1 {
//     font-size: 34px;
//     font-weight: 600;
//     line-height: 40px;
//     letter-spacing: 0;
//     flex-grow: 1;
//     font-family: "poppins";
//     color: #344c9f;
//     margin-right: 24px;
//     margin-bottom: 0;
//   }
// }

.sider{
  h2.primary{
    font-size: 26px;
    font-weight: 600;
  }

 
}

.info-list{
  padding-left: 25px;
  list-style:  disc;
  > li{
   margin-bottom:5px;
   
 }

 ol{
   padding-left: 25px;
   list-style: initial;
 }
}

.block{
  section h1 {
    font-family : "Poppins";
  }
}

.price-large{
  font-size: 44px;
  font-family: "Roboto";
  color : $green;
  line-height: normal;

}


.warning-label{
  color: red;
  font-weight: 500;
  margin-right:0.4em;
  svg{
      font-size : 1.5em;
  }
  
  svg{
    // position: relative;
    // top: 5px;
  }
}
.recommended{
  color: #7cb143;
border: 2px solid #7cb143;
border-radius: 20px;
font-size: 12px;
font-weight: 700;
padding: 2px 5px;
background-color: white;

}

.ws-text{
  font-size: 14px;

  color: $black;
  p{
    color: $black;
  }
  // height: 300px;
  // overflow-y: scroll;
  .t-center{
    margin-bottom: 10px;
  }
 p{
  text-align: justify;
 } 
}

.input-sentence{
  margin-bottom: 11px;
}

.block{
  @include md-t;
}

.MuiSvgIcon-root{
  font-size : 1.3em !important;
}