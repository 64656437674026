@import 'styles/base/variables.scss';


.oil-range{
    
    display: flex;
    input{
        flex-grow: 1;
        width: 100%;
    
    }
    .oil-values-markers{
        padding-left: 5px;
    }

        .oil-preview{
            width: 100px;
            padding-left: 20px;
            font-weight: 500;
            white-space: nowrap;
        }

}