@import "styles/base/variables.scss";
@import "styles/base/mixins.scss";

$cell-vertical-space: 13px;
$cell-horizontal-space: 10px;

.custom-ant-table {
  width: 100%;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  background-color: white;
  td{
    color : $black;
  }p

  table,
  th {
    border-radius: 0 !important;
  }

  td {
    padding: $cell-vertical-space !important;
    vertical-align: middle;
  }

  .ant-table-thead th,
  .ant-table-thead td {
    background-color: $light-grey4;
    border-bottom: none;
    font-size: 11px;
    font-weight: 400;
    // @include font(11px, 400, 15px, 0);
    color: $grey;
    padding-left: $cell-vertical-space !important;
    padding-right: $cell-vertical-space !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    word-break: normal;
    vertical-align: middle;
  }

  .MuiSvgIcon-root{
    font-size: 1.5em !important;
  }

  .attachment-file-name {
    width: 260px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;

    &.sm{
      width: 100px;
    }
  }

  .basic_info {
    display: inline-block;
    padding-top: 5px;
    .name {
      font-family: "sofia-pro";
      @include md-t;
      color: $black;
    }
    .details {
      text-align: left;
      @include sm-t;
      font-family: "Roboto";
      color: $grey;
      span {
        margin-right: 10px;
      }
    }
  }

  .column-upper {
    &:first-letter {
      text-transform: uppercase;
    }
  }

  .edit_icon,
  .close_icon {
    color: $dark-blue;
    cursor: pointer;
    top: 4px;
  }

  .ant-table-title {
    background-color: $light-grey2;
    border-bottom: 1px solid $light-grey;
    padding: $cell-vertical-space;
    top: 0;
  }

  .disabled {
    background-color: $light-grey2;
    color: $grey;
    .name {
      color: $grey;
      font-style: italic;
    }
  }
  .bulk-action {
    padding: 15px;
    padding-left: 0;
    font-size: 14px !important;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    float: left;

    :global(.form-check) {
      display: inline-block;
      margin-bottom: 0;
    }
  }

  .ant-table-selection-column {
    text-align: left;
  }
  
  .ant-table-header-column {
    // text-align: left;
    word-break: keep-all;
  
    overflow-wrap: normal;
  }
}


.table_wrapper {
  

  

  .pagination {
    float: right;
    padding-top:10px;
  }
  
  .ant-table-pagination {
    padding-right: 15px;
  }

 
}


.waste-table {
  width: 100%;

  .waste_code {
    white-space: nowrap;
  }
  .actions {
    height: 24px;
    line-height: 24px;
    color: $dark-blue;
    white-space: nowrap;
    .edit,
    .delete {
      font-size: 26px;
      cursor: pointer;
    }
  }
  td {
    padding: $cell-vertical-space;
    vertical-align: middle;
  }
  tr {
    border-bottom: 1px solid $light-grey3;
  }
}


//non antd table for simple listings, eg. OS-OP1 list for user page 
.simple-table {
  th {
    padding-bottom: 10px;
    padding-right: 20px;
    font-weight: 500;
    text-align: left;

  }
  td {
    padding-right: 20px;
    padding-bottom: 5px;

    &:last-child{
      padding-right: 0;
    }

  }
}

@media screen and (max-width: 1300px) {
  .table_wrapper {
    .ant-table {
      tr > th {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
      td {
        padding: 10px !important;
      }
    }
  }
}
