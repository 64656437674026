@import "./fonts";
@import "./layout";
@import "../layout/form-layout";

html {
  font-family: "Roboto", "Arial";
  
}

body{
  // overflow-y: hidden;
  color : $black;
}

form {
  position: relative;
}

* {
  box-sizing: border-box;
}

a {
  transition: all 0.2s ease;
  text-decoration: none;
  text-decoration: none;
}

hr {
  border: 0;
  border-bottom: 1px solid #0000001f;
}

.hover {
  cursor: pointer;
}

.forbidden {
  cursor : not-allowed;
}

.separator {
  width: 100%;
  background-color: $border;
  height: 1px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    background-color: white;
    padding: 5px;
  }
}

.ant-layout-sider-collapsed {
  padding: 0 !important;
  margin: 0 !important;
}

.ant-dropdown {
  z-index: 9999;
}

.p-10{
  padding: 10;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}


.mt-15 {
  margin-top: 15px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.pt-0 {
  padding-top: 0px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.pl-8 {
  padding-left: 8px !important;
}
.pr-8 {
  padding-right: 8px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mr-10 {
  margin-right: 10px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.border-b {
  border-bottom: 1px solid $light-grey;
}

.border-b-light {
  border-bottom: 1px solid $grey2;
}


.close-form {
  position: static;
  font-size: 24px !important;
  cursor: pointer;
}

.relative {
  position: relative;
}

.h-100 {
  height: 100% !important;
}
.h-100vh{
  height: 100vh ;
}

.min-h-100vh{
  min-height: 100vh !important ;
}

.align-verticaly {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.inline {
  display: inline;
}

.i-b {
  display: inline-block !important;
  // width: 100%;
}

.b {
  display: block;
}

.f-right {
  float: right;
}

.page-content {
  padding: $content-padding;
}

.flex {
  display: flex !important;
  align-items: center;

  &__align-top {
    align-items: start !important ;
  }
}
.flex-left {
  @include flex(row, center, left);
}

.flex-left-bottom {
  @include flex(row, end, left);
}

.flex-center {
  @include flex(row, center, center);
}

.flex-top-left {
  @include flex(row, start, left);
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.grow-1 {
  flex-grow: 1;
}

.t-left {
  text-align: left !important;
}

.t-right {
  text-align: right;
}

.t-center {
  text-align: center;
}

.y-scroll {
  overflow-y: scroll;
}

a[disabled] {
  background: gray !important;
}

.toasted-container .toasted {
  @include size(240px!important, 48px!important);
  @include flex(row!important, center!important, center!important);
  font-family: "sofia-pro";
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #f7f8fa;
}

::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}

.new-badge {
  color: $green;
  border: 2px solid $green;
  border-radius: 20px;
  background-color: white;
  left: 10px;
  font-size: 10px;
  padding: 2px 6px 1px 6px;
  font-family: "Roboto-Bold";
}

.small-form {
  max-width: 500px;
}

.form-section {
  margin-bottom: 50px;
}

.block {
  position: relative;
  &.alert-block {
    background-color: $skin-color !important;
    border: 1px solid red;
  }
  background-color: white;
  padding: 24px;
  > section {
    border-bottom: 1px solid $border;
    padding-bottom: 20px;
    margin-bottom: 20px;
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom:0;
    }
  }

  

  &.no-padding {
    padding: 0;
  }

  &__padding {
    padding: 24px;
  }

  &--full-height {
    min-height: calc(100vh - 115px);
  }
}

.breadcrumbs {

  margin-bottom: 10px;
  min-height: 30px;
  display: flex;
  // align-items: center;
  .help-icon{
    text-align: right;

    flex-grow: 1;
    svg{
      cursor: pointer;
    }
  }
  @include sm-l-t; 
  .arrow {
    margin: 0 8px;
  }
  a{
    color: $primary !important;
  }
}

status-icon {
  font-size: 46px;
  margin-right: 10px;
}
.w-100 {
  width: 100%;
}

.ant-row-flex-top {
  align-items : flex-start !important;
}

// .ant-radio-button-wrapper-checked{
//   background: #fd3939;
// }

// .ant-radio-button-checked{
//   background: #fd3939;
// }

// .ant-radio-button-wrapper{
  // margin: 2px;
// }
// 
// .ant-radio-button-wrapper{
  // height: 100%;
  // padding: 5px;
// }

.leaflet-wrapper{
  margin: 2px;
  height: 100%;
  padding: 5px;
  border: 5px solid #fff0;
  ::before{
    content: none !important;
    background-color: white !important;
  }

  &:first-child{
    border-left: 5px solid #fff0;
    border-radius: 4px 0 0 4px;
  }

  .ant-radio-button-wrapper::before {
    /* position: absolute; */
    /* top: 0; */
    /* left:; */
    /* display: block; */
    /* width:; */
    /* height: 100%; */
    /* background-color: #d9d9d9; */
    content: none !important;
    background-color: white !important;
}

  .ant-radio-button-wrapper-checked{
    padding: 7px;
    border: 0px;
    ::before{
      content: none !important;
      background-color: white !important;
    }
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    border: 5px solid $primary;
    padding: 5px;
    
    ::before{
      content: none !important;
      background-color: white !important;
    }
  }
}