.vehicle-img{
    border-radius: 6px;
    background-color: $light-grey2;
    width: 50px;
    height: 50px;
    text-align: center;
    padding-top: 17px;
    float: left;
    margin-right: 10px;
    

    svg.green{
      position: relative;
      top: -5px;
    }
  }