$default-text : rgba(0,0,0,.65);
$primary: #4a90e2;
$heading: #344C9F;
$background: #EFF1F3;
$black: #000000DE;
// $black: black;
$black-2 : black;
$menu: #000000C7;
$error: #F4033B;
$red: #D0021B;
$white: #FFFFFFB3;
$important: #7BB139;
$green: #5EAF00;
$greenHover: #7BB139;
$light-grey: #0000001F;
$light-grey2: #F7F8F9;
$light-grey3: #efefef;
$light-grey4 : #EAECEE;

$grey: #0000008A;
$grey2: #00000008;
$grey3 : #e6e8ea60;
$grey4 : #CBCBCB;
$grey5: #000000;
$border: #00000029;
$blue-chill: #1283AC;
$persian-indigo: #3D1185;
$jaguar: #20073A;
$orange : #E09300;
$dark-blue : #00000061;
$skin-color: #FFF8E6;


//screen width breakpoints
$xs-size: 500px;
$sm-size: 767px;
$md-size: 991px;

// UI style colors
$primary-blue :#1C2C62;


//spaces

$content-padding: 24px;
$header-height : 64px;
$footer-height: 57px;

// * new integrator * //
$blue : #2997CF;

$navbar-height: 56px;
$background2: #F7F7F7;