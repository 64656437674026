@import "styles/base/variables.scss";
@import "styles/base/mixins.scss";

.ant-form-item {
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
}

.ant-form-item-label {
  line-height: 14px;
}

.ant-form-item-label label {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  font-family: "Roboto";
  color: #0000008a;

  &:after {
    content: "";
  }
}

.ant-select {
  width: 100%;
  vertical-align: top;
}
.ant-input,
.ant-select-selection {
  padding: 0;
  // font-size: 14px;
  // font-weight: 400;
  
  @include md-t;
  line-height: 24px;
  letter-spacing: 0;
  color: #000000de;
  border: 0;
  outline: none;
  box-shadow: none;
  border-bottom: 1px solid #0000001f;
  border-radius: 0;
  background-color: transparent;
  overflow-y: hidden;
  &:active,
  &:focus {
    outline: none;
  }
}

textarea.ant-input {
  border: 1px solid #0000001f;
//   background-color: white;
}

.ant-select-selection__rendered {
  padding: 0;
  margin-left: 0;
}
.input-error {
  color: $red;
  font-weight: 400;
  line-height: 17px !important;
  // font-family: 'Roboto';
  font-size: 12px;
  // position: absolute;
  position: absolute;
  display: block;
  top: 0;
}

.absolute-msg {
  position: relative;
}

.custom-cbx-error {
  margin-left: 25px;
  bottom: -15px;
  position: absolute;
  top: auto;
  bottom: 0;
}

// .ant-select + .input-error {
//   position: relative;
//   top: -10px;
// }

.search-field {
  svg {
    position: absolute;
    // top: 3px;
    bottom: 0;
  }
  input {
    padding-left: 30px;
  }

  .search-glass {
    left: 0;
  }
  .clear-search {
    right: 0;
    cursor: pointer;
    z-index: 99999;
  }
}

.radio_form_field {
  margin-bottom: 20px;
}
.ant-radio-group {
  p {
    margin-bottom: 0;
  }
}
.ant-radio-group.vertical {
  margin-bottom: 15px;
  .ant-radio-wrapper {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    white-space: normal;

    p {
      margin-bottom: 0;
    }
  }
  span.ant-radio + * {
    display: inline-block;
    flex-grow: 1;
  }
}

.ant-checkbox-wrapper {
  display: flex;
  // margin-bottom: 10px;
  &.inline {
    display: inline-flex;
  }
}

.check-form-fields {
  &.vertical {
    .checkbox {
      margin-left: 0 !important;
      width: 100%;
      margin-bottom: 18px;
    }

    .ant-checkbox-wrapper {
      margin-left: 0 !important;
    }
  }
  &.horizontal {
    .ant-checkbox-wrapper {
      display: inline-block;
    }
  }
}

.cbx-title {
  @include md-t;
  // line-height: inherit;
  display: flex;
  align-items: center;
  color : $black;
}

.checkbox {
  display: flex;
  align-items: center;
  svg {
    width: 18px;
    // position: relative;
    // top: 6px;
    margin-right: 5px;
  }

  p {
    margin-bottom: 0;
  }
}

.ant-checkbox-wrapper-disabled {
  .cbx-title {
    color: $dark-blue;
  }
}

.ant-checkbox + span {
  display: inline-block;
}

.ant-form-item-required:before {
  display: none;
}

.ant-form-item-required:after {
  display: inline-block;
  margin-right: 4px;

  // font-size: 16px;
  font-family: "Roboto";
  line-height: 1;
  content: "*" !important;
}

.ant-form-item-control {
  line-height: 30px;
  position: relative;
}

.ant-calendar-picker {
  width: 100%;
}

.ant-upload {
  width: 100%;
  .ant-input {
    cursor: pointer;
  }
}
// .ant-upload-list-item{
//   position: absolute;
//   top: 17px;
// }

.ant-calendar-range-picker-separator {
  position: relative;
  color: transparent;

  top: 5px;
}
.ant-calendar-range-picker-separator:after {
  content: "-";
  color: black;
}

.select-wrapper {
  position: relative;
  .input-error {
    bottom: 0;
  }
}

.ant-tooltip-inner {
  font-family: "Roboto";
  background-color: white;
  color:$black;
  font-size: 14px;
  // font-style: normal;
  // font-weight: 400;
  white-space: pre-wrap;
}
