@import 'styles/base/variables.scss';
@import 'styles/base/mixins.scss';

.spin-container{
  position: absolute;
  width: 100%;
  height: 100%;
  // min-height: 400px; 
  top:0;
  left:0;
  text-align: center;
  z-index: 999999;
  &.fixed{
    position:fixed;
  }
  .overlay{

    &.no-transparent{
      opacity : 1 !important;
    }
    width: 100%;
    height: 100%;
    background-color: white;
    opacity : 0.7;
    position: absolute;
    &.grey{
      background-color: $light-grey2;
    }
  }
  .ant-spin{
    display: inline-block;
    @include verticalAlign;
  }
}
