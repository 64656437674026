.ReactModal__Overlay{
    z-index: 999;
}

.modal{
    border: none;
    background-color: rgba(255, 255, 255, 0.75);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 2px;
    outline: none;
    padding: 30px;
    position: relative;
    box-shadow: 0px 19px 38px #00000042;
    .close {
        
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;

    }
}

.confirm-modal{
        width: 500px;
}

// .react-confirm-alert-overlay{
//     .{

//     }

// }