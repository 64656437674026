.form-layout {
  // .ant-row-flex{
  //   min-height: 100vh;
  // }

  background: linear-gradient(
    90deg,
    $jaguar 0%,
    $persian-indigo 50%,
    $blue-chill 100%
  );
  @include flex(row, flex-start, space-between);
  @include minSize(100%, calc(100vh - 112px));
  height: 100%;
  position: relative;
  padding-left: 30px;

  @media only screen and (max-width: 992px) {
    flex-wrap: wrap;
    border: 0;
    height: auto;
  }
  > .container {
    width: 100%;
    background-color: white;
    padding-left: 25px;
    > .back {
      left: 10px;
    }
  }
}

.list-radios{

  li{
    padding-left: 16px;
    margin-bottom: 20px;
    &::marker{
      color:#00000061;
    }
  }
  list-style: decimal;
  margin-left: 15px;
  .ant-form-item{
    display: inline-block;
    vertical-align: top;
    
  }
  .ant-form-item-label{
    max-width: 420px;
    white-space: normal;
    text-align: left;
    line-height: 20px;
    label{
      font-size: 14px;
      color : $black;
    }
  }
  .ant-form-item-control-wrapper{
    display: inline-block;
    padding-left: 20px;
  }
}

.form{
  section{
    border-bottom : none !important;
    
    padding-bottom: 0 !important;
  }
  h3{
    color : $heading;
    font-family: 'poppins';
    margin-bottom: 15px;
  }
}