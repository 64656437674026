
@import "styles/base/variables.scss";
@import "styles/base/mixins.scss";
.table-list {
  background-color: white;

  li {
    padding-bottom: 20px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    // border-top : 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;
    &:first-child {
      border-top: none;
    }
  }
  svg.circle {
    font-size: 40px;
  }
}
// iframe {
//   display: none;
// }

.ant-table-pagination {
  padding-right: 15px;
}

.check-list {
  color : $black;
  margin-top: 10px;
  &__element {
    padding: 8px 0;
    border-top: 1px solid $grey2;
    display: flex;
    align-items: center;

    // &:last-child {
    //   border-bottom: none;
    // }
  }
}

.request-order {
  li {
    position: relative;
    counter-increment: steps;
    padding-bottom: 20px;
    padding-top: 5px;
    padding-bottom: 20px;
    div {
      padding-left: 35px;
    }
    padding-bottom: 20px;

    &:before {
      position: absolute;
      left: 13px;
      top: 0;
      content: "";
      height: 100%;
      width: 0;
      border-left: 1px solid #dfdfdf;
    }
    &:last-child:before {
      display: none;
    }
  }
  li:after {
    position: absolute;
    top: 0;
    left: 0;
    content: counter(steps);
    border: 1px solid #dfdfdf;
    border-radius: 50%;
    color: #4a90e2;
    font-weight: 500;
    display: inline-block;
    height: 28px;
    width: 28px;
    line-height: 27px;
    text-align: center;
    background: #fff;
    font-size: 14px;
  }

  p {
    // color : $primary;
    // line-height: 20px;
    color : $grey;
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 0;
  }

  label {
    // color: $heading;
    color: $black;
    @include md-t;
    // @include font(14px, 500, 18px, 0);
    // margin-bottom: 5px;
    display: inline-block;
  }
}

.numbered-list {
  li {
    position: relative;
    counter-increment: steps;
    padding-bottom: 10px;
    padding-top: 3px;
    padding-left: 23px;
    // color: $grey;
    color : $black;
    @include md-t;
   
  }

  li:after {
    position: absolute;
    top: 0;
    left: 0;
    content: "" counter(steps) ". ";
    border: 1 px solid #dfdfdf;
    border-radius: 50%;
    color: #4a90e2;
    font-weight: 500;
    display: inline-block;
    height: 28 px;
    width: 28 px;
    line-height: 27px;
    text-align: center;
    background: #fff;
    font-size: 14px;
  }
}

.product-attributes {
  table{
    width: 100%;
  }
  th.product{
    margin-left: 20px;
    padding-left: 23px;
  }
  .ant-checkbox-wrapper {
    margin-bottom: 0 !important;
  }
  td {
    padding: 5px 0;
    vertical-align: middle;
  }

  th{
    padding: 5px 0;
  }
}

.bullet-list{
list-style: disc;
padding-left: 20px;

li{
  // font-size: 14px;
  @include md-t;
  // margin-bottom: 5px;
}
}