@import "src/styles/base/variables.scss";
@import "src/styles/base/mixins.scss";
// @import "src/styles/modules/waste-list.module.scss";

.waste_code{
    width: 100px;
}

.desc {
  width: 40%;
  a{
    color: inherit;
    text-decoration: none;
  }
}

.forwarded{
  // width: 250px;
  text-align: right;
  white-space: nowrap;
  align-items: center;
  white-space: nowrap;
}

.forwarded_bar {
  height: 7px;
  display:inline-block;
  // width : 100px;
  margin: 0 10px;
  background-color: $light-grey2;
  .fill {
    background-color: $green;
    height: 100%;
  }
}



.kg{
    white-space: nowrap;
}

.percent{
  width:60px;
  display:inline-block;
}

.history-search{
  // select{
  //   width: 60%;
  // }
  button{
    width: 120px;
    position: relative;
    bottom:1px;
  }
  :global(.grow-1){
    padding-right: 20px;
  }
}
