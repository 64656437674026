@import 'styles/base/variables.scss';
@import 'styles/base/mixins.scss';

#menu {
 
  .section-header{
    @include font(12px, 400, normal, "");

    padding-bottom:10px;
    padding-top: 10px;
    display: inline-block;
    width:100%;
    span{
      margin-left: 28px;
    }
    hr{
      display: none;
    }
  }

  &.ant-menu-inline-collapsed{
    .section-header{
      span {display:none;}
      hr{
        display: block;
      }
    } 
  }
  // height: 100%;
  margin-bottom: 20px;
  border-right: 0;
  font-family: "Roboto";

  .ant-menu-submenu-arrow{
    display: none;
  }
  
  .anticon {
    vertical-align: text-top;
    color: $dark-blue;
    font-size: 25px;
    margin-right: 25px;

  }
  .anticon-png{
    width: 20px;
    margin-right: 20px;
  }
  .ant-menu-item:after {
    display: none;
  }
  .ant-menu-item-selected {
    background-color: #fafafa;
  }
  .ant-menu-item {
    height: 40px;
    line-height: 40px;
  }
  .ant-menu-item{
    padding-left: 28px !important;
  }
  .ant-menu-item,
  .ant-menu-item a {
    @include font(14px, 500, "", "");
    display: flex;
    align-items: center;
    color: $black;
   
    &.ant-menu-item-selected span,
    &.ant-menu-item-selected a {
      color: $primary;
    }
  }

  .ant-menu-submenu-title span {
    @include font(12px, 400, "", "");

    display: flex;
    align-items: center;
    color: $grey;
    &.ant-menu-item-selected span,
    &.ant-menu-item-selected a {
      color: $grey;
    }
  }

  .ant-menu-submenu-title {
    margin-bottom: -5px !important;
  }

  .ant-menu-submenu-selected{
    background-color: #fafafa;
  }

  .menu-item--disabled{
    color : lighten($grey, 50) ; 
    cursor: initial;
    svg{
      color : lighten($grey, 20) ; 
    }
  }

}

.menu-settings{
  h4{
    margin-bottom: 10px;
  }

  .checkbox span{
    @include md-b-t;
  }
}
