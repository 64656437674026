@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=block');

// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=block');

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=block');

$font-path : "../../fonts/";

// @font-face {
//   font-family: 'sofia-pro';
//   font-style: normal;
//   font-weight: 600;
//   font-display: block;
//   src: local('sofia-pro SemiBold'), local('sofia-pro-SemiBold'), url("#{$font-path}pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2") format('woff2');
//   unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// @font-face {
//   font-family: 'sofia-pro';
//   font-style: normal;
//   font-weight: 600;
//   font-display: block;
//   src: local('sofia-pro SemiBold'), local('sofia-pro-SemiBold'), url("#{$font-path}pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2") format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }

// @font-face {
//   font-family: 'sofia-pro';
//   font-style: normal;
//   font-weight: 600;
//   font-display: block;
//   src: local('sofia-pro SemiBold'), local('sofia-pro-SemiBold'), url("#{$font-path}pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2") format('woff2');
//   unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// @font-face {
//   font-family: 'sofia-pro';
//   font-style: normal;
//   font-weight: 600;
//   font-display: block;
//   src: local('sofia-pro SemiBold'), local('sofia-pro-SemiBold'), url("#{$font-path}pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2") format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 300;
//   font-display: block;
//   src: local('Roboto Light'), local('Roboto-Light'), url("#{$font-path}KFOlCnqEu92Fr1MmSU5fChc4EsA.woff2") format('woff2');
//   unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 300;
//   font-display: block;
//   src: local('Roboto Light'), local('Roboto-Light'), url("#{$font-path}KFOlCnqEu92Fr1MmSU5fBBc4.woff2") format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 400;
//   font-display: block;
//   src: local('Roboto'), local('Roboto Regular'), url("#{$font-path}KFOmCnqEu92Fr1Mu7GxKOzY.woff2") format('woff2');
//   unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 400;
//   font-display: block;
//   src: local('Roboto'), local('Roboto Regular'), url("#{$font-path}KFOmCnqEu92Fr1Mu4mxK.woff2") format('woff2');
//   // src: local('Roboto'), local('Roboto Regular'), url("#{$font-path}KFOmCnqEu92Fr1Mu7GxKOzY.woff2") format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 500;
//   font-display: block;
//   src: local('Roboto Medium'), local('Roboto-Medium'), url("#{$font-path}KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2") format('woff2');
//   unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 500;
//   font-display: block;
//   src: local('Roboto Medium'), local('Roboto-Medium'), url("#{$font-path}KFOlCnqEu92Fr1MmEU9fBBc4.woff2") format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 700;
//   font-display: block;
//   src: local('Roboto Bold'), local('Roboto-Bold'), url("#{$font-path}KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2") format('woff2');
//   unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 700;
//   font-display: block;
//   src: local('Roboto Bold'), local('Roboto-Bold'), url("#{$font-path}KFOlCnqEu92Fr1MmWUlfBBc4.woff2") format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }

@font-face {
  font-family: 'sofia-pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src:  url("#{$font-path}sofia-pro-regular.otf") format('opentype');
  // unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'sofia-pro';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src:  url("#{$font-path}sofia-pro-medium.otf") format('opentype');
  // unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'sofia-pro';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src:  url("#{$font-path}sofia-pro-semibold.otf") format('opentype');
  // unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}



@font-face {
  font-family: 'sofia-pro';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src:  url("#{$font-path}sofia-pro-bold.otf") format('opentype');
  // unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}