@import 'styles/base/variables.scss';
@import 'styles/base/mixins.scss';
.button , button.button{
  line-height: 36px;
  @include minSize(auto, 36px);
  @include flex(row, center, center);
  transition: all 0.2s ease;
  border:none;
  width: 100%;
  border-radius: 6px;
  font-size: 14px;
  svg{
    line-height: 37px;
    position: relative;
    top: 7px;
    left: -5px;
  }

  &.green{
    background-color: $primary;
    color: white;
    box-shadow: 0px 8px 8px #0000001F;
    font-weight: 500;
  }
  
  &.primary {
    background-color: $primary;
    color: #fff;
    box-shadow: 0px 8px 8px #0000001F;

    &[disabled], &[disabled]:hover{
      background-color: lighten($primary, 25%);
      color: white;
      box-shadow: 0px 8px 8px #0000001F;
    }
    &:hover {
      background-color: $heading;
    }
  }
  &.danger {
    background-color: $error;
    color:white;
    box-shadow: 0px 8px 8px #0000001F;
  }
  &.inline{
    display: inline-block;
    width: auto;

  }
  a{
    color: inherit;
    text-align: center;
    width: 100%;
    padding: 0 20px;
    display: inline-block;

    &::first-letter{
      text-transform: uppercase;
    }
  }
  &.white {
    background-color: #fff;
    border: 1px solid $border;
    color: $primary;
    font-weight: 500;
    &:hover {
      color: $heading;
    }

    &.bold{
      border: none;
      color : $black;
      font-weight: 500;
    }
  }
  &.transparent {
    background-color: trnasparent;
    color: $primary;
    &:hover {
      color: $heading;
    }
  }
  &.green {
    background-color: $green;
    color: #fff!important;
    box-shadow: 0px 8px 8px #0000001F;
    &:hover {
      background-color: $greenHover;
    }
  }

  &.disabled, &.disabled:hover{
    background-color: lighten($primary, 25%);
    color: white;
    box-shadow: 0px 8px 8px #0000001F;
  }
}

.two-buttons{
  .button{
      width: 48%;
      display: inline-block;
      &:nth-child(2){
        float:right;
      }
  }
}


// other type of buttons

.close-button{
  color: $grey;
  font-size: 25px !important;
  cursor: pointer;
}