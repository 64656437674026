@import "styles/base/variables.scss";

.form-content {
  padding-top: 80px;
  width: 100%;
  max-width: 1300px;
  margin: auto;


}


.bdo-report-form {
  .close-page{
        
    top: 85px;
    
    
  }
  .report-warning{
    max-width: 70%;
    font-size: 16px;
    margin: auto;
    margin-bottom: 20px;
    svg { font-size: 55px; }
  }
  padding:0 30px 25px;
  padding-bottom: 60px;
  position: relative;
  min-height: 100vh;
  

  .success,
  .error {
    font-size: 55px !important;
    margin-bottom: 25px;
  }
  .success {
    color: $green;
  }
  .error {
    color: red;
  }
  p.large {
    margin-bottom: 30px;
  }
  .buttons-panel {
    background-color: white;
    border-top: 1px solid $grey3;
    padding: 10px;
    position: fixed;
  bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9999;
    display: inline-block;
  }
  .block {
    margin-bottom: 20px;
    padding: 35px;
    position: relative;
    // min-height: 500px;
 
  }

  .steps-nav {
    position: relative;
    width: 100%;
    height: 70px;

    ul {
      display: flex;
      height: 100%;
      align-items: center;
    }
    li {
      width: 33%;
      padding: 0 !important;
      z-index: 1;

      div {
        background-color: $background;
        display: inline-block;
        padding-right: 10px;
      }
      &:before {
        content: "";
        height: 1px;
        background-color: $light-grey;
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        z-index: -1;
      }
      &:last-child {
        &:before {
          content: none;
        }
      }
      &:after {
        top: -5px;
      }

      &.active {
        color: $primary;
        label {
          color: $primary;
        }
        &:after {
          background-color: $primary;
          border-color: $primary;
          color: white;
        }
      }
    }
  }

  .api-key-loading {
    height: 700px;

    .block {
      height: 100%;
      padding: 0 250px;
    }
  }

  .bottom-dashed {
    border-bottom: 1px dashed $grey;
    padding-bottom: 30px;
  }

  .success-list {
    margin: auto;
    max-width: 850px;
    li {
      margin-bottom: 10px;
    }
    .nested {
      margin-left: 20px;
    }
  }

  h1 svg {
    font-size: 1.5em;
    margin-right: 10px;
  }

  .instruction-btn{
    position:absolute;
    top:20px;
    right:20px;
  }
}

.terms-conditions {
  height: 120px;
  overflow-y: scroll;
  border: 1px solid $light-grey;
  padding: 10px;
  
  font-family: "Roboto";
  line-height: normal;
  li {
    margin-bottom: 10px;
    text-align: justify;
  }
}
