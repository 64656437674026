@import 'src/styles/base/variables.scss';

.top_message {
  margin-bottom: 20px;
  color: #565655;
  background: #FFF8E6;
  padding: 20px 22px;
  // border-bottom: 2px solid #cacaca;
  font-size: 14px;
  font-family: Roboto-Regular;
  box-shadow: 0px 10px 20px #00000029;
  svg{
    width: 25px;
    height: 25px;
  }
  
  // &.error_message {
  //   border: 1px solid darken($red-color,5%);
  
    > :global(.flex){
        align-items :  flex-start !important;
    }
  
  // }
}

.header {
  float: right;
  width: 200px;
  margin: 10px 0 30px 20px;
  text-align: right;
}

.action {
  float: right;
  width: 80px;
  margin: 30px 0 30px 20px;
}

.main {
  color: #565655;
  margin-bottom: 10px;
  font-size: 18px;
  font-family: Roboto-Bold;
}

.confirm-alert{
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  button{
    margin-right: 10px;
    width:100px;
  }

  :global(.inverted-button){
    border: 1px solid darken($light-grey2, 10%);
  }
}
