@import 'styles/base/variables.scss';
@import 'styles/base/mixins.scss';


.saldo.flex {
  line-height: initial;
  .ant-statistic {
    line-height: 1;
  }
  .ant-statistic-content{
    color: #fff;
  }
  .ant-statistic-title {
    margin: 0;
    color: $white !important;
    font-size: 12px;
    font-family: "Roboto";
  }
  .ant-statistic-content-value {
    @include font(16px, 700, "", "");
    font-family: "Roboto";
  }
  .icon {
    margin-right: 12px;
  }
}
@media only screen and (max-width: 992px) {
  .saldo {
    display: none;
  }
  .flex {
    margin-left: 0!important;
  }
}
