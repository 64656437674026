@import "src/styles/base/variables.scss";
@import "src/styles/base/mixins.scss";
@import "src/styles/modules/tabs.module.scss";
// @import "src/styles/modules/waste-list.module.scss";

.summary{
    span{
        @include sm-t;
        font-size: 13px;
        display:block;
    }
    display: flex;
    align-items: center;
}

.waste_kg{
    width: 20%;

}
.amount, .kg{
    display: inline-block;
}
.amount{
    font-size: 48px;
    margin-right: 7px;
    color: $black;
}

.kg{
    font-size: 22px;
    font-family: "Roboto-Light";
    font-weight: 600;
    // color: $dark3;
}

.forwarded{
    display:flex;
    align-items: center;
    .forwarded_kg{
        white-space: nowrap;
    }

    // .amount{
    //     font-size: 20px;
    // }
}

.bar_container{
    font-size: 24px;
    margin-left: 20px;
}

.forwarded_bar {
    height: 12px;
    margin-top:2px;
    display:block;
    width : 165px;
    background-color: $light-grey2;
    .fill {
      background-color: $green;
      height: 100%;
    }
  }

.forward_btn{
    flex-grow: 1;
    text-align: right;
}

.amount_field{
    display: flex;
    align-items: center;
    .amount_sec_label{
        margin-right: 20px;
    }
    > input{
        width: 100px !important;
        margin-right: 10px;
    }
    a{
        white-space: nowrap;
    }
}

.waste-details{
    :global(.sider){
        width: 1000px;
    }
}