// /* @styled-jsx=scoped */
@import "styles/base/variables.scss";
@import "styles/base/mixins.scss";
@import "src/styles/components/common/image.scss";

.my-vehicles {
  overflow: auto;

  display: flex;
  flex-direction: column;
  // .ant-table-thead > tr > th,
  // .ant-table-thead td {
  //   padding: 5px 10px !important;
  // }

  // .custom-ant-table {
  
  //   td {
  //     padding: 4px 10px !important;
  //   }
  // }


  .checkbox {
    width: 16px;
    height: 16px;
  }

  &__section {
    @include flex(row, flex-start, space-between);
  }
  &__filters {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    width: 1000px !important;
    &.ant-layout-sider {
      transition: none;
    }
    // height: calc(100vh - 68px);
    overflow-x: hidden;
    // height: 80vh;
    // height: calc(100vh - 68px);
    max-height: calc(100vh - #{$navbar-height});
    right: 0;
    // overflow-y: auto;
    overflow-x: auto;
    position: absolute;
    z-index: 999;

    // padding-bottom:50px;
    @media only screen and (max-width: 992px) {
      right: 0;
      background: rgb(232, 234, 237);
      width: 100%;
      max-width: 100%;
      z-index: 9999;
      height: calc(100vh - 64px);
    }
  }
  // &__table {
  //   background-color: #fff;
  //   // box-shadow: 0px 1px 3px #00000014;
  //   // margin-top: 80px;
  //   // margin-top: 20px;
  //   width: 100%;
  //   @media only screen and (max-width: 992px) {
  //     overflow: hidden;
  //   }
  // }
  header {
    // @include flex(row, center, space-between);
    position: relative;

    @media only screen and (max-width: 992px) {
      flex-wrap: wrap;
    }
    > div {
      @include flex(row, center, flex-start);

      @media only screen and (max-width: 992px) {
        flex-wrap: wrap;
      }
      width: 100%;
      // h1 {
      //   @include font(34px, 600, 40px, 0);
      //   flex-grow: 1;
      //   font-family: "poppins";
      //   color: $heading;
      //   margin-right: 24px;
      //   margin-bottom: 0;
      // }
      /deep/ {
        .button {
          max-width: 150px;
          position: absolute;
          right: 0;
          @media only screen and (max-width: 992px) {
            margin: 12px 0;
            max-width: 100%;
          }
        }
      }
    }
  }
  /deep/ {
    .transparent {
      border: 1px solid $border;
      max-width: 130px;
      float: right;
      @media only screen and (max-width: 992px) {
        max-width: 100%;
      }
    }
    .ant-pagination {
      margin-right: 24px;
      @media only screen and (max-width: 992px) {
        margin: 20px auto;
        width: 100%;
        text-align: center;
      }
    }
  }
}

.filters {
  font-family: Roboto;
  .sider-close {
    display: none;
  }
  header {
    @include flex(row, center, space-between);
    margin-bottom: 16px;
  }
  h2 {
    margin-bottom: 0;
  }
  h5 {
    @include font(12px, 400, 14px, 0);
    font-family: "Roboto";
    color: $grey;
    margin-bottom: 0;
    width: 100%;
  }
  .small {
    @include flex(row, center, space-between);
    flex-wrap: wrap;
    .ant-form-item {
      width: 46%;
    }
  }
  .ant-select-selection,
  .ant-input {
    @include font(16px, 400, 24px, 0);
    color: $black;
    border: 0;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #0000001f;
    border-radius: 0;
    padding-left: 0;
    margin-left: 0;
    background-color: transparent;
  }
  .checkbox {
    color: $black;
  }
  .ant-select-selection__rendered {
    margin-left: 0;
  }
  .ant-form-item-label {
    line-height: 14px;
    label {
      @include font(12px, 400, 14px, 0);
      font-family: "Roboto";
      color: $grey;
    }
  }
  .button {
    margin-bottom: 4px;
    @media only screen and (max-width: 992px) {
      margin-bottom: 20px;
    }
  }
}

.custom-ant-table {
  &.bordered {

  }

  .ant-table-fixed-header .ant-table-body-inner {
    overflow: auto !important;
  }
  /* table styles */
  // overflow: scroll;
  td {
    position: relative;
  }
  .action {
    height: 100%;
    // display: flex;
    display: inline-block;
    // align-items: center;
    // justify-content: center;
    // position: absolute;
    top: 0;
    // width: 100%;
    text-align: left;
    svg {
      // @include verticalAlign;
      width: 30px;
      height: 30px;
    }
    .add {
      color: $light-grey;
    }
  }
  .vehicle-basic-info {
    display: block;
    padding-top: 5px;
    .make-model {
      max-width: 200px;
      font-family: "Roboto";
      @include font(14px, 500, 22px, 0);
      color: $black;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .year-vin {
      text-align: left;
      @include font(12px, 300, 16px, 0);
      font-family: "Roboto";
      color: #0000008a;
      span {
        margin-right: 10px;
      }
    }
  }
  // .ant-table-thead>tr>th{
  //   text-align: left !important;
  // }
  // tr:last-child {
  //   span {
  //     font-family: "Roboto";
  //     @include font(12px, 400, 16px, 0);
  //     color: #0000008a !important;
  //   }
  //   th {
  //     padding-top: 0;
  //     padding-bottom: 5px;
  //   }
  // }
}

.vehicle-info {
  justify-content: left !important;
}

.view-filters {
  display: flex;
  width: 100%;
  align-items: center;
}

.my-vehicle-buttons {
  display: inline-block;
  .button {
    display: inline-block;
    width: auto;
    margin-left: 10px;
  }
}

.ant-table-middle
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr
  > td {
  vertical-align: middle;
}

// * new integrator *//

.vehicle_product_attributes {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.12);

  .price {
    width: 150px;
    text-align: right;
  }
}

@media screen and (min-width: 1300px) {
  // .custom-ant-table {
  //   overflow: scroll;
  // }
  .ant-table-body {
    overflow: auto !important;
  }
}

@media screen and (max-width: 1750px) {
  .my-vehicles {
    &__main.sider-visible {
      padding-right: 20px !important;
    }
    &__filters {
      position: fixed;
      overflow-x: auto;

      // height: 80vh;
      height: calc(100vh - 68px);

      .sider-close {
        display: inline-block;
        cursor: pointer;
      }
    }
  }
}

.no-agreement {
  svg {
    margin-right: 10px;
    width: 18px;
  }

  border-bottom: 1px dashed $light-grey;
  padding-bottom: 20px;
  margin-bottom: 15px;
}

.no-product {
  color: $light-grey;
  font-size: 16px;
}
