@import "src/styles/base/variables.scss";

div.waste_group_header {


  // margin-bottom: 10px;
  border: 1px solid $light-grey2;
  .arrow {
    cursor: pointer;
  }
  th {
    background-color: $light-grey2;
    text-align: left !important;
  }
  :global(.ant-table) {
    width: 100%;
  }
 
  .code {
    // width: 80px;
    color : $grey;
  }
  td {
    padding: 10px 5px;
    color : $black;
  }



  &.hidden {
    // .waste_list{
    //     display:none;
    // }
    tbody {
      display: none;
    }
  }

  &--large {
    td {
      padding: 12px 10px;
    }
  }
}
