@import "src/styles/base/variables.scss";
// @import "src/styles/modules/waste-list.module.scss";

.address-row.hidden{
    display: none;
  }
.order_input{
    float: right;
}
.filters{
    float:left;
}

.select_input{
    padding-top: 0;
    padding-bottom: 0;
    height: 32px;
}

.forward_input{
    input{
        width: 100px;
        margin-right:10px;
    }
    
}

.forward_desc{
    width: 55%;
}

.report-btn-container{
    display: inline-block;
    width: 202px;
    float: right;
    font-family : Roboto-Medium;
    > div{
        border: 1px solid $light-grey2;
        padding: 0 5px;
        border-radius: 10px;
        display: inline-block;
        margin-top: 4px;
        font-size: 11px;

    }
   
}