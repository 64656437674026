@import 'src/styles/base/variables.scss';
@import 'src/styles/base/mixins.scss';

$orange-b : #f3be58;

.notifications{
  .notifications-header{
    .close-button{
      top: 0;
      right: 0;
      position: absolute;
    }
  }
  .card {
    background-color: #00000008;
    border-radius: 4px;
    box-shadow: 0px 1px 3px $border;
    margin-bottom: 8px;
    padding: 14px 24px 14px 34px;
    font-family: "Roboto";
    position: relative;
    background-color:  white;
    border: 1px solid $background2;

    &--confirm{
      // border: 3px solid $green;
    }

    &--alert{
      border: 1px solid $orange-b;
      background-color: $background2;
      .card__title{
        color : $orange-b;
      }
    }

    &--warning{
      border: 1px solid red;
      background-color: $background2;
      .card__title{
        color : red;
      }

    }

    &__car,
    &__footer {
      @include font(12px, 400, 16px, 0);
      color: $grey;
    }
    &__title {
      @include font(14px, 500, 18px, 0);
      margin-bottom: 0.5em;
      color: $black;
    }
    &__avatar {
      @include position(absolute, 12px, "", "", -20px);
      @include size(42px, 42px);
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 50%;
    }
    &__description {
      @include font(14px, 400, 18px, 0);
      p{
        color: $grey;
        margin-bottom: 10px;
      }
    }
    &__footer {
      font-style: italic;
      margin-top: 12px;
    }
    .button {
      font-size: 14px;
      &--half {
        max-width: 48%;
      }
    }
    .flex {
      @include flex(row, center, space-between!important);
    }
  }
}
