@import 'styles/base/variables.scss';

.bdo-report-popup{
    .close-page{
        
        top: 85px;
        
        
      }
    position: relative;
    height: calc(100vh - 56px);
    
    .block{
        padding-bottom:0;
    }
    .new-service{
        
        box-shadow: inset 0px 0px 0px 2px $green;
        li:last-child{
            border-bottom: 2px solid $grey3;
            margin-bottom:15px;
        }
    }

    .bdo-info{
        max-width: 1100px;
        margin: auto;
        display: block;
        width : 100%;
        position: relative;
        top: 50%;
        transform : translateY(-50%);
    }
    h2{
        font-size: 26px;
    }
    ul{
        margin: 0 -25px;
    }
    li{
        padding: 15px 20px;
        border-top: 2px solid $grey3;
        display:flex;
        align-items: center;
        height: 60px;
        
        svg{
            margin-right: 15px;
            color : $grey4;
        }
    }
    .bdo-instruction{
        max-width: 1100px;
        margin: auto;
        display: block;
        width : 100%;
        position: relative;
        padding-top: 20px;
        height: calc(100vh - 56px);
        padding-bottom: 20px;
    }

  
}

@media screen and (max-width: $md-size){
    
    .bdo-info{
        top: auto !important;
        transform: none !important;
    }
}