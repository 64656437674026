@import "styles/base/mixins.scss";
@import "styles/base/variables.scss";

$close-icon-size: 25px;

.file_dropzone {
  background-color: white;
  border: 1px dashed $border;
  text-align: center;
  height: 200px;
  cursor: poiner;
  .dropzone_text {
    @include verticalAlign;
  }
  p {
    text-align: center;
    &.primary {
      margin-bottom: 10px;
    }
  }
  svg {
    color: $light-grey;
    font-size: 50px !important;
  }
  ul {
    width: 100%;
  }
}

.file_dropzone_uploader {
  .thumbnail {
  
    .file-name {
      padding-right: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .file-icon{
      color :$dark-blue;
    }
    

    width: 100%;
    padding: 8px;
    border: 1px solid #e0e0e0;
    margin-bottom: 5px;
    position: relative;
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
      
    }
    .remove-file {
      position: absolute;
      top: 8px;
      right: 15px;
      cursor: pointer;
      color: $dark-blue;

      width: $close-icon-size;
      height: $close-icon-size;
      svg {
        width: $close-icon-size;
        height: $close-icon-size;
      }
    }
  }
}
